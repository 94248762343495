import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"
import Title from "../components/Title/Title"
import Underline from "../components/Underline/Underline"
import MetaImage from "../images/paula-1.jpeg"
import * as FAQStyles from "./faq.module.css"

export default function FAQ({ data }) {
    const content = data.allContentYaml.edges[0].node
    const faq = [
        {
            id: 1,
            question: "What is the difference between counselling and psychotherapy?",
            answer: <><p>The effectiveness of both counselling and psychotherapy depends on the relationship formed between therapist and client.</p><p>Whereas counselling is usually short-term and focuses on a specific problem, psychotherapy explores in more depth how past experiences affect our present patterns of behaviour.</p></>,
        },
        {
            id: 2,
            question: "What type of problems can therapy help with?",
            answer: <p>Some people may seek counselling / psychotherapy following a difficult life event such as a bereavement or divorce or having experienced trauma or abuse. Others may be struggling with feelings of anxiety, depression, or stress. It could be a desire to improve your relationships with others through self-exploration, or to find support with focus and direction.</p>,
        },
        {
            id: 3,
            question: "How can therapy help me?",
            answer: <><p>Sometimes it helps to speak to someone independent of your circle of family and friends so that you can fully explore your concerns. Therapy provides the opportunity for you to do this in a safe, non-judgemental environment, in which you are helped to process your feelings, to explore your reactions, and start to develop resilience to face life’s challenges.</p><p>However, you don’t need to have a specific problem to benefit from therapy. It can help anyone to increase self-awareness, confidence, and self-esteem, leading to better emotional and psychological wellbeing.</p></>,
        },
        {
            id: 4,
            question: "Are our sessions confidential?",
            answer: <><p>Yes, I take your confidentiality very seriously; it is really important that you feel able to bring all of yourself to therapy.</p><p>I am a registered member of the British Association for Counselling and Psychotherapy (BACP) and am bound by its Ethical Framework. Part of this requires me to work under supervision, to ensure that my work with you is ethical and safe. During these sessions your identity will be kept confidential.</p><p>In exceptional circumstances, where I believe there may be a risk of harm to either yourself or others, or there is disclosure of certain illegal acts, I may need to seek help outside of our counselling relationship; however, I would always try to discuss this with you in the first instance.</p><p>Full information regarding the limits of confidentiality will be provided during our first session.</p></>,
        },
        {
            id: 5,
            question: "I'm interested, how do I get in touch?",
            answer: <p>It would be great to hear from you. Simply get in touch using the <Link to="/appointments/">contact form</Link> or email me at <a href="mailto:hello@paulaabbey.co.uk">hello@paulaabbey.co.uk</a> and we can arrange an initial 20 minute call, without obligation. This gives us the opportunity to start to get to know each other. We can discuss what is troubling you, your expectations of therapy and I can answer any questions you may have. If we decide to work together, we will agree a mutually suitable timeslot for our weekly or fortnightly sessions.</p>,
        },
        {
            id: 6,
            question: "How long does a counselling session last?",
            answer: <p>Sessions last 50 minutes and take place on a regular day and time each week, which helps us to establish our therapeutic relationship.</p>,
        },
        {
            id: 7,
            question: "How much does it cost?",
            answer: <><p>Fees start at £{content.fees} per session and are payable by bank transfer in advance (bank details are included in my Welcome Pack).</p><p>I politely request that payment is made a minimum of 48 hours prior to the session.</p></>,
        },
        {
            id: 8,
            question: "What if I need to cancel or reschedule a session?",
            answer: <p>You can rearrange or cancel appointments up to 48 hours before the appointment time. This can be done by phone, text or email. I reserve the right to charge payment in full if notice is not given or an appointment is missed.</p>,
        },
        {
            id: 9,
            question: "How many sessions do I need?",
            answer: <p>The number of sessions a client has differs depending on the issue being brought to therapy; long term issues often taking longer to work through. At the beginning of therapy, I usually contract for six sessions, and thereafter continue on an open-ended basis, whilst reviewing client progress regularly.</p>,
        },
    ]

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQ | Paula Abbey Counselling & Psychotherapy</title>
                <meta name="title" content="FAQ | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="Find answers to commonly asked questions about counselling, my services, booking appointments and more. If you can't find an answer for your question, please get in touch." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://paulaabbey.co.uk/faq/" />
                <meta property="og:title" content="FAQ | Paula Abbey Counselling & Psychotherapy" />
                <meta property="og:description" content="Find answers to commonly asked questions about counselling, my services, booking appointments and more. If you can't find an answer for your question, please get in touch." />
                <meta property="og:image" content={MetaImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://paulaabbey.co.uk/faq/" />
                <meta name="twitter:title" content="FAQ | Paula Abbey Counselling & Psychotherapy" />
                <meta name="twitter:description" content="Find answers to commonly asked questions about counselling, my services, booking appointments and more. If you can't find an answer for your question, please get in touch." />
                <meta name="twitter:image" content={MetaImage} />
            </Helmet>
            <Title>
                <h1>Frequently Asked Questions</h1>
            </Title>
            <section className={FAQStyles.grid}>
                <div className={FAQStyles.contentsContainer}>
                    <div className={FAQStyles.contentsBox}>
                        <h3>FAQ Contents</h3>
                        <ul>
                            {faq.map(faq => {
                                return (
                                    <li key={faq.id}>
                                        <a href={`#faq-${faq.id}`}>{faq.question}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className={FAQStyles.faqContainer}>
                    {
                        faq.map(faq => {
                            return (
                                <div id={`faq-${faq.id}`}>
                                    <Underline>
                                        <h2 style={{fontSize: "var(--type-l)"}}>{faq.question}</h2>
                                    </Underline>
                                    {faq.answer}
                                </div>
                            )
                        })
                    }
                </div>
            </section>
            <section className={FAQStyles.cta}>
                <CTA>
                    <h2>Haven't found the answer to your question?</h2>
                    <Button type="internal" colour="primary" to="/appointments/">Get in touch</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        allContentYaml {
            edges {
                node {
                    fees
                    discount
                }
            }
        }
    }
`